export const getScrollParent = (node: Element | null): Element | null => {
    if (node === null) {
        return null;
    }
    const styles = getComputedStyle(node);
    const isOverflowYScroll = styles.overflowY === 'auto' || styles.overflowY === 'scroll';
    const isBody = node === document.body;
    const isNodeScrollable = (node.scrollHeight > node.clientHeight) && isOverflowYScroll;

    if (isNodeScrollable || isBody) {
        return node;
    }
    return getScrollParent(node.parentElement);
};

export default null;
