<template>
    <Component
        :is="renderer"
        ref="component"
        :field="field"
        :field-id="fieldId"
        :form-name="formName"
        :required="required"
        :disabled="disabled"
        :novalidate="novalidate"
        :horizontal="horizontal"
        :labeled="labeled"
        :autofocus="autofocus"
        :no-required-marker="noRequiredMarker"
        :label-size="labelSize"
        class="standard-field"
        @change="$emit('change', $event)"
        @section-change="$emit('sectionChange', $event)"
        @form-progress="$emit('formProgress', $event)"
        @update-form="onUpdateForm"
        @input="$emit('input')"
    >
        <template
            v-for="slot in Object.keys($slots)"
            #[slot]="scope"
        >
            <slot
                :name="slot"
                v-bind="scope"
            />
        </template>
    </Component>
</template>

<script lang="ts" setup>
import {
    computed,
    ref,
    defineAsyncComponent,
    AsyncComponentOptions,
} from 'vue';
import BaseField from './BaseField.vue';
import FIELD_RENDER_TYPES from '../../../scripts/helpers/fieldRenderTypes';
import { BaseFieldProps } from './BaseFieldProps';

const components: Record<string, ReturnType<typeof defineAsyncComponent>> = {
    StandardForm: defineAsyncComponent(() => import('./StandardForm.vue')),
    SectionalForm: defineAsyncComponent(() => import('./SectionalForm.vue')),
    InputField: defineAsyncComponent(() => import('./InputField.vue')),
    IntegerInputField: defineAsyncComponent(() => import('./IntegerInputField.vue')),
    SelectField: defineAsyncComponent(() => import('./SelectField.vue')),
    MultiSelectField: defineAsyncComponent(() => import('./MultiSelectField.vue')),
    MultiSelectChekboxesField: defineAsyncComponent(() => import('./MultiSelectCheckboxesField.vue')),
    RetentionField: defineAsyncComponent(() => import('./RetentionField.vue')),
    ZoomField: defineAsyncComponent(() => import('./ZoomAccountField.vue')),
    FileField: defineAsyncComponent(() => import('./FileField.vue')),
    FileUploadField: defineAsyncComponent(() => import('./FileUploadField.vue')),
    VideoUploadField: defineAsyncComponent(() => import('./VideoUploadField.vue')),
    CheckboxField: defineAsyncComponent(() => import('./CheckboxField.vue')),
    TumblerField: defineAsyncComponent(() => import('./TumblerField.vue')),
    TextAreaField: defineAsyncComponent(() => import('./TextAreaField.vue')),
    PinCodeField: defineAsyncComponent(() => import('./PinCodeField.vue')),
    FormCheckboxTreeField: defineAsyncComponent(() => import('./FormCheckboxTreeField.vue')),
    PasswordInputField: defineAsyncComponent(() => import('./PasswordInputField.vue')),
    RightsCheckboxTreeField: defineAsyncComponent(() => import('./RightsCheckboxTreeField.vue')),
    UsersGroupsChoiceField: defineAsyncComponent(() => import('./UsersGroupsChoiceField.vue')),
    ColorPickerField: defineAsyncComponent(() => import('./ColorPickerField.vue')),
    GridSizeField: defineAsyncComponent(() => import('./GridSizeField.vue')),
    AudioField: defineAsyncComponent(() => import('./AudioField.vue')),
    MultiFilesField: defineAsyncComponent(() => import('./MultiFilesField.vue')),
    ScheduleRulesField: defineAsyncComponent(() => import('./ScheduleRulesField.vue')),
    AsyncTypeaheadField: defineAsyncComponent(() => import('./AsyncTypeaheadField.vue')),
    FormRadioTreeField: defineAsyncComponent(() => import('./FormRadioTreeField/FormRadioTreeField.vue')),
    RadioGroupField: defineAsyncComponent(() => import('./RadioGroupField.vue')),
    DateFilterField: defineAsyncComponent(() => import('./DateFilterField.vue')),
    ImageField: defineAsyncComponent(() => import('./Evaluations/ImageField.vue')),
    EvaluationStaticTextField: defineAsyncComponent(() => import('./Evaluations/StaticTextField.vue')),
    DescriptionField: defineAsyncComponent(() => import('./Evaluations/DescriptionField.vue')),
    EvaluationCheckboxesField: defineAsyncComponent(() => import('./Evaluations/EvaluationCheckboxesField.vue')),
    EvaluationRadioField: defineAsyncComponent(() => import('./Evaluations/EvaluationRadioField.vue')),
    DateRangeField: defineAsyncComponent(() => import('./DateRangeField.vue')),
    DirectDateRangeField: defineAsyncComponent(() => import('./DirectDateRangeField.vue')),
    PresetsControlField: defineAsyncComponent(() => import('./PresetsControlField.vue')),
    ArrayField: defineAsyncComponent(() => import('./ArrayField.vue')),
    FilterInfoItemsContainerField: defineAsyncComponent(() => import('./FilterInfoItemsContainerField.vue')),
    EvaluationCollapseField: defineAsyncComponent(() => import('./EvaluationCollapseField.vue')),
    TemplateSelectionCollectionField: defineAsyncComponent(() => import('./TemplateSelectionCollectionField.vue')),
    DurationField: defineAsyncComponent(() => import('./DurationField.vue')),
    RestrictAccessField: defineAsyncComponent(() => import('./RestrictAccessField.vue')),
    StaticTextField: defineAsyncComponent(() => import('./StaticTextField.vue')),
    DateField: defineAsyncComponent(() => import('./DateField.vue')),
    StaticImageField: defineAsyncComponent(() => import('./StaticImageField.vue')),
};

// @todo remove optional
const fieldTypes: { [type in FIELD_RENDER_TYPES]?: string } = {
    [FIELD_RENDER_TYPES.FORM]: 'StandardForm',
    [FIELD_RENDER_TYPES.SECTIONAL_FORM]: 'SectionalForm',
    [FIELD_RENDER_TYPES.INPUT]: 'InputField',
    [FIELD_RENDER_TYPES.INPUT_INTEGER]: 'IntegerInputField',
    [FIELD_RENDER_TYPES.SELECT]: 'SelectField',
    [FIELD_RENDER_TYPES.MULTI_SELECT]: 'MultiSelectField',
    [FIELD_RENDER_TYPES.MULTI_SELECT_WITH_LABELS_BELOW]: 'MultiSelectField',
    [FIELD_RENDER_TYPES.ZOOM_ACCOUNT]: 'ZoomAccountField',
    [FIELD_RENDER_TYPES.RETENTION_RULE]: 'RetentionField',
    [FIELD_RENDER_TYPES.CHECKBOX]: 'CheckboxField',
    [FIELD_RENDER_TYPES.TUMBLER]: 'TumblerField',
    [FIELD_RENDER_TYPES.FILE]: 'FileField',
    [FIELD_RENDER_TYPES.FILE_UPLOAD]: 'FileUploadField',
    [FIELD_RENDER_TYPES.VIDEO_UPLOAD]: 'VideoUploadField',
    [FIELD_RENDER_TYPES.INPUT_PASSWORD]: 'PasswordInputField',
    [FIELD_RENDER_TYPES.TEXTAREA]: 'TextAreaField',
    [FIELD_RENDER_TYPES.PIN_CODE]: 'PinCodeField',
    [FIELD_RENDER_TYPES.CHECKBOX_TREE]: 'FormCheckboxTreeField',
    [FIELD_RENDER_TYPES.USERS_GROUPS_CHOICE]: 'UsersGroupsChoiceField',
    [FIELD_RENDER_TYPES.RADIO_CHOICE]: 'RadioGroupField',
    [FIELD_RENDER_TYPES.RADIO_TREE]: 'FormRadioTreeField',
    [FIELD_RENDER_TYPES.AUTHOR_CHOICE]: 'FormRadioTreeField',
    [FIELD_RENDER_TYPES.RIGHTS_CHOICE]: 'RightsCheckboxTreeField',
    [FIELD_RENDER_TYPES.GRID_SIZE]: 'GridSizeField',
    [FIELD_RENDER_TYPES.ARRAY]: 'ArrayField',
    [FIELD_RENDER_TYPES.ARRAY_WITH_PROTOTYPE]: 'ArrayField',
    [FIELD_RENDER_TYPES.COLOR_PICKER]: 'ColorPickerField',
    [FIELD_RENDER_TYPES.MULTI_FILES]: 'MultiFilesField',
    [FIELD_RENDER_TYPES.AUDIO]: 'AudioField',
    [FIELD_RENDER_TYPES.SCHEDULE_RULES]: 'ScheduleRulesField',
    [FIELD_RENDER_TYPES.FILTER_INFO_ITEMS_CONTAINER]: 'FilterInfoItemsContainerField',
    [FIELD_RENDER_TYPES.EVALUATION_COLLAPSE]: 'EvaluationCollapseField',
    [FIELD_RENDER_TYPES.DATE_FILTER]: 'DateFilterField',
    [FIELD_RENDER_TYPES.ASYNC_TYPEAHEAD]: 'AsyncTypeaheadField',
    [FIELD_RENDER_TYPES.PRESETS_CONTROL]: 'PresetsControlField',
    [FIELD_RENDER_TYPES.EVALUATION_DESCRIPTION]: 'DescriptionField',
    [FIELD_RENDER_TYPES.EVALUATION_IMAGE]: 'ImageField',
    [FIELD_RENDER_TYPES.EVALUATION_STATIC_TEXT]: 'EvaluationStaticTextField',
    [FIELD_RENDER_TYPES.CHECKBOXES]: 'EvaluationCheckboxesField',
    [FIELD_RENDER_TYPES.RADIO]: 'EvaluationRadioField',
    [FIELD_RENDER_TYPES.TEMPLATE_SELECTION_COLLECTION]: 'TemplateSelectionCollectionField',
    [FIELD_RENDER_TYPES.DATE_RANGE]: 'DateRangeField',
    [FIELD_RENDER_TYPES.DIRECT_DATE_RANGE]: 'DirectDateRangeField',
    [FIELD_RENDER_TYPES.MULTI_SELECT_CHECKBOXES]: 'MultiSelectChekboxesField',
    [FIELD_RENDER_TYPES.DURATION]: 'DurationField',
    [FIELD_RENDER_TYPES.RESTRICT_ACCESS]: 'RestrictAccessField',
    [FIELD_RENDER_TYPES.STATIC_TEXT]: 'StaticTextField',
    [FIELD_RENDER_TYPES.CALENDAR]: 'DateField',
    [FIELD_RENDER_TYPES.STATIC_IMAGE]: 'StaticImageField',
};

const component = ref<typeof BaseField | null>(null);

type Props = {
    field: BaseFieldProps['field'];
    labeled?: BaseFieldProps['labeled'];
    fieldId?: BaseFieldProps['fieldId'];
    formName?: BaseFieldProps['formName'];
    disabled?: BaseFieldProps['disabled'];
    novalidate?: BaseFieldProps['novalidate'];
    horizontal?: BaseFieldProps['horizontal'];
    autofocus?: BaseFieldProps['autofocus'];
    noRequiredMarker?: BaseFieldProps['noRequiredMarker'];
    parentCypressId?: BaseFieldProps['parentCypressId'];
    noLabel?: BaseFieldProps['noLabel'];
    labelSize?: BaseFieldProps['labelSize'];
};

const props = withDefaults(defineProps<Props>(), {
    labeled: true,
    fieldId: null,
    disabled: false,
    novalidate: false,
    horizontal: false,
    autofocus: false,
    noRequiredMarker: false,
    parentCypressId: null,
    noLabel: false,
    labelSize: null,
});

const emit = defineEmits([
    'updateForm',
    'formProgress',
    'change',
    'sectionChange',
    'input',
]);

const checkValidity = (): boolean => !!component.value?.checkValidity();
const reportValidity = (): boolean => !!component.value?.reportValidity();

const renderer = computed<ReturnType<typeof defineAsyncComponent> | null>(() => {
    const renderAs = props.field?.renderAs as FIELD_RENDER_TYPES;
    if (renderAs === FIELD_RENDER_TYPES.NONE) {
        return null;
    }

    const rendererValue = fieldTypes[renderAs];
    if (!rendererValue) {
        throw new Error(`Unable to find the component for ${renderAs} renderer.`);
    }

    const componentValue = components[rendererValue] as AsyncComponentOptions;
    if (!componentValue) {
        throw new Error(`Unable to find component by name (${rendererValue}).`);
    }

    return componentValue;
});

const required = computed(() => props.field.isRequired as boolean);

const onUpdateForm = (preserveUpdatedData = true): void => {
    setTimeout(() => {
        emit('updateForm', preserveUpdatedData);
    }, 100);
};

const focus = () => {
    if (!component.value) {
        return;
    }

    if (
        'focus' in component.value
        && typeof component.value.focus === 'function'
    ) {
        component.value.focus();
    }
};

defineExpose({
    checkValidity,
    reportValidity,
    focus,
});
</script>

<style scoped lang="scss">
@import '../../../styles/abstracts/spacings';

.standard-field:not(:last-child) {
    margin-bottom: $spacing-s;
}

</style>
