<template>
    <div
        class="root with-overlay"
        :class="{ 'has-overlay': hasOverlay }"
    >
        <TheMenu
            v-show="!hideMenu"
        />
        <div
            v-if="!arePermissionsLoaded || !isLoaded"
            id="global-loader-wrapper"
            class="global-loader-wrapper"
        >
            <div class="global-loader">
                <Lottie
                    loop
                    :animation-data="loader"
                />
            </div>
        </div>
        <TheStoreSidebar />
        <RouterView
            v-if="arePermissionsLoaded"
            class="page-container"
            @success-login="onSuccessLogin"
        />
        <TheNotification />
    </div>
</template>

<script lang="ts" setup>
import {
    ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import TheNotification from '../../components/TheNotification.vue';
import Loader from '../../../animations/loader.json';
import TheMenu from '../../components/Menu/TheMenu.vue';
import TheStoreSidebar from '../../components/Sidebar/TheStoreSidebar.vue';

import {
    globalSettingsModule,
    isAnonymousModule,
    permissionsModule as pM,
    timezoneModule,
    userModule,
    syncModule,
    colorsModule,
    notificationModule,
    overlayModule,
    loadingModule,
} from '../../store';

import ROUTE_NAMES from '../../routes/routeNames';
import isUserSessionExpired from '../../../scripts/helpers/isUserSessionExpired';
import appApi from '../../../scripts/api/app/AppApi';
import ColorsApi from '../../../scripts/api/colors/ColorsApi';
import { addOnWowzaLowSpaceHandler } from '../../../scripts/api/wowza/SpaceCheckApi';
import { Notification } from '../../../scripts/helpers/Notification';
import { NotificationVariant } from '../../../scripts/helpers/Notification/Notification';
import { Variant } from '../../components/Elements/Button/Variant';
import { setTheme } from '../../../scripts/mixins/themeManager';
import { addOnUserLogoutSoonHandler } from '../../../scripts/api/user/userApi';
import HistoryEventBus, { HISTORY_POP_EVENT } from '../../Event/HistoryEventBus';
import { LoginResponse } from '../../../scripts/api/login/LoginApi';

const loader = Loader;

const CHECK_SESSION_INTERVAL_IN_MILLISECONDS = 60000;

const checkUserSessionTimer = ref<ReturnType<typeof setTimeout> | null>(null);

const isLoaded = computed(() => loadingModule.isLoaded);

const hasOverlay = computed(() => overlayModule.hasOverlay);

const route = useRoute();

const router = useRouter();

const loadUserDetails = async () => {
    try {
        const details = await appApi.getUserDetails();
        userModule.setUserDetails(details);
        await setTheme(userModule.theme);
    } catch (e) {
        userModule.reset();
    }
};

const loadGlobalSettings = async () => {
    const {
        dateFormat, streamingMethod, commentOverlaySize, time, sync,
    } = await appApi.getGlobalSettings();
    globalSettingsModule.setDateFormatByName(dateFormat);
    globalSettingsModule.setStreamingMethod(streamingMethod);
    globalSettingsModule.setCommentOverlayFontSize(commentOverlaySize);
    timezoneModule.configureTime(time);
    syncModule.configureSync(sync);
};

const loadColors = async () => {
    const { colors, defaultColor } = await ColorsApi.getColors();
    colorsModule.setColors(colors);
    colorsModule.setDefaultColor(defaultColor);
};

const subscribeToLowServerSpace = () => {
    if (userModule.hasPermission(pM.allPermissions.general)) {
        addOnWowzaLowSpaceHandler(({ text, title }) => {
            const notification = new Notification({
                title,
                text,
                icon: null,
                variant: NotificationVariant.TITLE_WITH_MESSAGE,
                withCancel: true,
                cancelText: 'Got it',
                cancelVariant: Variant.DARK,
            });
            notificationModule.promptUser(notification);
        });
    }
};

const subscribeToUserLogoutSoon = () => {
    addOnUserLogoutSoonHandler(({ text, title }) => {
        const notification = new Notification({
            title,
            text,
            icon: 'LockIcon',
            variant: NotificationVariant.TITLE_WITH_MESSAGE,
            withCancel: true,
            cancelText: 'Got it',
            cancelVariant: Variant.DARK,
        });
        notificationModule.promptUser(notification);
    });
};

const checkUserSession = async () => {
    const isLoginPage = route.name === ROUTE_NAMES.LOGIN_MAIN;
    const isAnonymousPage = isAnonymousModule.isAnonymous;
    if (isLoginPage || isAnonymousPage) return;

    if (await isUserSessionExpired()) {
        localStorage.clear();
        userModule.reset();
        await router.push({ name: ROUTE_NAMES.LOGIN_MAIN });
    }
};

const enableUserSessionValidation = async () => {
    if (checkUserSessionTimer.value) { return; }
    checkUserSessionTimer.value = setInterval(
        checkUserSession, CHECK_SESSION_INTERVAL_IN_MILLISECONDS,
    );
};

const addBackForwardButtonsRootEvent = () => {
    window.onpopstate = () => {
        HistoryEventBus.emit(HISTORY_POP_EVENT, HISTORY_POP_EVENT);
    };
};

onMounted(() => {
    loadUserDetails();
    loadGlobalSettings();
    loadColors();
    subscribeToLowServerSpace();
    subscribeToUserLogoutSoon();
    if (!isAnonymousModule.isAnonymous) enableUserSessionValidation();
    addBackForwardButtonsRootEvent();
});

const disableUserSessionValidation = () => {
    if (checkUserSessionTimer.value) {
        clearInterval(checkUserSessionTimer.value);
        checkUserSessionTimer.value = null;
    }
};

onBeforeUnmount(() => {
    disableUserSessionValidation();
    syncModule.unconfigureSync();
});

const arePermissionsLoaded = computed(
    () => (userModule.arePermissionsSet && pM.areAllPermissionsSet)
        || isAnonymousModule.isAnonymous,
);

const loadPermissions = async () => {
    const { all, user } = await appApi.getPermissions();
    pM.setAllPermissions(all);
    userModule.setPermissions(user);
};

const onSuccessLogin = async (response: LoginResponse) => {
    await loadUserDetails();
    if (!isAnonymousModule.isAnonymous) {
        await loadPermissions();
        await enableUserSessionValidation();
    }
    if (userModule.isPasswordExpired) {
        await router.push({ name: ROUTE_NAMES.USER_SETTINGS });
    }
    if (typeof response.redirect === 'string') {
        window.location.href = response.redirect as string;
    }
};

const hideMenu = computed(() => Boolean(route.name === undefined || route.meta?.hideMenu));
</script>

<style lang="scss">
@import '../../../styles/abstracts/z-indexes';

/* stylelint-disable selector-max-id */
#app {
    height: 100%;
}

.root {
    display: flex;

    flex-direction: row;
    flex-grow: 1;

    align-items: stretch;
    justify-content: stretch;

    width: 100%;
    height: 100%;
}

.page-container {
    display: flex;

    flex-grow: 1;

    min-height: 100%;

    background-color: var(--theme-color-surface-background);
}

.global-loader-wrapper {
    position: fixed;
    top: 0;

    z-index: $loader-z-index;

    display: flex;

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 100%;

    background: #000;
    opacity: 0.6;
}

.global-loader {
    width: 7rem;
}
</style>
