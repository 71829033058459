<template>
    <TooltipOption
        v-if="isLgOrMore"
        :cypress-id="cypressId"
        :to="to"
        class="dropdown-button-option"
        @click="$emit('click', $event)"
    >
        <slot />
    </TooltipOption>
    <ContextMenuOption
        v-else
        :cypress-id="cypressId"
        :to="to"
        class="dropdown-button-option"
        @click="$emit('click', $event)"
    >
        <slot />
    </ContextMenuOption>
</template>

<script lang="ts" setup>
import { RouteLocation } from 'vue-router';
import { computed } from 'vue';
import TooltipOption from './TooltipOption.vue';
import ContextMenuOption from './ContextMenuOption.vue';
import { mediaQueriesModule } from '../../store';

type Props = {
    to?: string | RouteLocation | null,
    cypressId?: string | null,
}

withDefaults(defineProps<Props>(), {
    to: null,
    cypressId: null,
});

const isLgOrMore = computed(() => mediaQueriesModule.isLgOrMore);
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/variables';

.dropdown-button-option {
    color: var(--theme-color-text-primary);

    &:hover {
        background-color: var(--theme-color-surface-secondary-default);
    }

    &:first-child:hover {
        border-radius: $border-radius $border-radius 0 0;
    }

    &:last-child:hover {
        border-radius: 0 0 $border-radius $border-radius;
    }

    &:not(:first-child):not(:last-child):hover {
        border-radius: 0;
    }
}
</style>
