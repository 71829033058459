<template>
    <div
        class="scrollable"
        :class="{
            'scrollable--vertical': !horizontal,
            'scrollable--horizontal': horizontal,
            'scrollable--external': external,
        }"
        :style="{
            '--offset-top': offsetTopInPx,
        }"
    >
        <div
            ref="content"
            class="scrollable__content"
            :class="contentClass"
            @scroll.passive="onScroll"
        >
            <slot />
        </div>

        <div
            v-show="hasScrollbar"
            class="scrollable__scrollbar scrollbar"
            :class="[
                {
                    'scrollable__scrollbar--active': isActive,
                    'scrollbar--active': isActive,
                },
                scrollbarClass,
            ]"
        >
            <div
                class="scrollbar__track"
                :style="trackStyles"
                @mousedown="startTrackMove"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    ref, onMounted, onBeforeUnmount, computed,
} from 'vue';
import { debounce } from 'lodash-es';

const SCROLL_REACH_END_DEBOUNCE = 200;

const props = defineProps({
    horizontal: { type: Boolean, default: false },
    contentClass: { type: String, default: null },
    scrollbarClass: { type: String },
    offsetTop: { type: Number, default: 0 },
    external: { type: Boolean, default: false },
});

  type Emits = {
    (event: 'scroll', type: Event): void,
    (event: 'scroll-reach-end', type: Event): void,
  };

const emit = defineEmits<Emits>();

const contentElement = ref<HTMLElement | null>(null);

const trackSize = ref<number | null>(null);

const trackOffset = ref(0);

const hasScrollbar = ref(true);

const trackMoveLocation = ref<{ x: number; y: number } | null>(null);

const debouncedEmitScrollReachEnd = ref<ReturnType<typeof debounce> | null>(null);

const offsetTopInPx = computed(() => `${props.offsetTop}px`);

const isActive = computed(() => trackMoveLocation.value !== null);

const getSize = () => {
    if (!contentElement.value) return 0;
    return props.horizontal
        ? contentElement.value.offsetWidth
        : contentElement.value.offsetHeight - props.offsetTop;
};

const getScrollableSize = () => {
    if (!contentElement.value) return 0;
    return props.horizontal
        ? contentElement.value.scrollWidth
        : contentElement.value.scrollHeight - props.offsetTop;
};

const getScroll = () => {
    if (!contentElement.value) return 0;
    return props.horizontal
        ? contentElement.value.scrollLeft
        : contentElement.value.scrollTop;
};

const refreshTrackStyles = () => {
    if (!contentElement.value) return;

    const size = getSize();
    const scrollableSize = getScrollableSize();

    const trackPercentHeight = size / scrollableSize;
    trackSize.value = trackPercentHeight * size;

    const scroll = getScroll();

    const maxOffset = size - (trackSize.value || 0);
    const maxScroll = scrollableSize - size;
    const scrollToTrackOffsetPercent = maxOffset / maxScroll || 0;
    trackOffset.value = scroll * scrollToTrackOffsetPercent;

    hasScrollbar.value = scrollableSize > size;
};

const onScroll = (event: Event) => {
    refreshTrackStyles();
    emit('scroll', event);
    debouncedEmitScrollReachEnd.value?.(event);
};

const emitScrollReachEnd = (event: Event) => {
    const target = event.target as HTMLElement;
    const size = props.horizontal
        ? target.offsetWidth
        : target.offsetHeight - props.offsetTop;
    const scrollableSize = props.horizontal
        ? target.scrollWidth
        : target.scrollHeight - props.offsetTop;
    const scroll = props.horizontal ? target.scrollLeft : target.scrollTop;

    if (size + scroll >= scrollableSize) {
        emit('scroll-reach-end', event);
    }
};

const startTrackMove = (event: MouseEvent) => {
    const { screenX, screenY } = event;
    trackMoveLocation.value = { x: screenX, y: screenY };
};

const calcScrollPercent = (size: number, scrollable: number, trackSizeValue: number) => {
    const maxOffset = size - trackSizeValue;
    const maxScroll = scrollable - size;
    return maxScroll / maxOffset;
};

const onTrackMove = (event: MouseEvent) => {
    if (!trackMoveLocation.value || !trackSize.value) return;

    event.preventDefault();
    event.stopPropagation();

    const { screenX, screenY } = event;
    const { x, y } = trackMoveLocation.value;
    trackMoveLocation.value = { x: screenX, y: screenY };

    const offsetY = screenY - y;
    const offsetX = screenX - x;
    const offset = props.horizontal ? offsetX : offsetY;

    const size = getSize();
    const scrollableSize = getScrollableSize();
    const scrollPercent = calcScrollPercent(size, scrollableSize, trackSize.value);

    const scrollRounded = Math.round(offset * scrollPercent);

    const scrollByX = props.horizontal ? scrollRounded : 0;
    const scrollByY = props.horizontal ? 0 : scrollRounded;
    contentElement.value?.scrollBy(scrollByX, scrollByY);
};

const stopTrackMove = () => {
    trackMoveLocation.value = null;
};

const trackStyles = computed(() => {
    const sizeProperty = props.horizontal ? 'width' : 'height';
    const offsetProperty = props.horizontal ? 'margin-left' : 'margin-top';

    return {
        [sizeProperty]: `${trackSize.value}px`,
        [offsetProperty]: `${trackOffset.value}px`,
    };
});

onMounted(() => {
    refreshTrackStyles();
    debouncedEmitScrollReachEnd.value = debounce(
        emitScrollReachEnd,
        SCROLL_REACH_END_DEBOUNCE,
    );

    document.addEventListener('mousemove', onTrackMove);
    document.addEventListener('mouseup', stopTrackMove);
});

onBeforeUnmount(() => {
    debouncedEmitScrollReachEnd.value?.cancel();
    document.removeEventListener('mousemove', onTrackMove);
    document.removeEventListener('mouseup', stopTrackMove);
});

</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/z-indexes';

$scrollbar-track-size: 0.1875rem;
$scrollbar-track-size-on-hover: $scrollbar-track-size * 2;
$scrollbar-padding: 0.125rem;

@mixin hide-default-scrollbar() {
    -ms-overflow-style: none; // IE
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        display: none; // Chrome, Safari
    }
}

.scrollable {
    position: relative;

    &--vertical {
        height: 100%;
    }

    &--horizontal {
        width: 100%;
    }

    &--external {
        $scrollbar-external-offset: $scrollbar-track-size-on-hover + $spacing-lm;
        flex-grow: 1;

        width: calc(100% + #{$scrollbar-external-offset});
        padding-right: $scrollbar-external-offset;
    }

    &__content {
        @include hide-default-scrollbar;

        height: 100%;
    }

    &--vertical &__content {
        overflow-y: auto;
    }

    &--horizontal &__content {
        overflow-x: auto;
    }

    &__scrollbar {
        opacity: 0;

        transition: opacity 0.5s;
    }

    &__scrollbar--active,
    &:hover > &__scrollbar {
        opacity: 1;
    }
}

.scrollbar {
    position: absolute;
    z-index: $scroll-bar-z-index;

    .scrollable--vertical & {
        top: var(--offset-top);
        right: 0;

        width: min-content;
        height: calc(100% - var(--offset-top));
        padding: 0 $scrollbar-padding;
    }

    .scrollable--horizontal & {
        bottom: 0;
        left: 0;

        width: 100%;
        height: min-content;
        padding: $scrollbar-padding 0;
    }

    &__track {
        background-color: var(--theme-color-surface-scroll);
        border-radius: 2px;

        transition: width 0.5s, height 0.5s, background-color 0.5s;

        &:hover {
            cursor: pointer;

        }
    }

    .scrollable--vertical &__track {
        width: $scrollbar-track-size;
    }

    .scrollable--horizontal &__track {
        height: $scrollbar-track-size;
    }

    &--active &__track,
    &:hover &__track {
        width: $scrollbar-track-size-on-hover;

        border-radius: 3px;
    }
}
</style>
