<template>
    <label
        class="checkbox-wrapper"
        @click.prevent="onClick"
    >
        <input
            :id="id"
            v-model="modelValue"
            type="checkbox"
            :name="name ?? ''"
            :disabled="disabled"
            :autofocus="autofocus"
            :data-cy="cypressId"
            :indeterminate="indeterminate"
            class="input visually-hidden"
        >

        <div class="checkbox">
            <CheckIcon class="check-icon" />
        </div>

        <slot />
    </label>
</template>

<script lang="ts" setup>
import CheckIcon from '../../../images/check.svg?component';

type Props = {
    id?: string,
    name?: string | null | undefined,
    disabled?: boolean,
    autofocus?: boolean,
    indeterminate?: boolean,
    cypressId?: string | null,
}

type Emits = {
    (e: 'click', value: Event): void,
}

const modelValue = defineModel<boolean>({ default: false });

withDefaults(
    defineProps<Props>(),
    {
        id: undefined,
        disabled: false,
        name: '',
        autofocus: false,
        indeterminate: false,
        cypressId: null,
    },
);

const emit = defineEmits<Emits>();

const onClick = (e: Event) => {
    modelValue.value = !modelValue.value;
    emit('click', e);
};

</script>

<style lang="scss">
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/colors_old';
@import '../../../styles/abstracts/variables';

$checkbox-size: 1.25rem;
$checkbox-check-size: 0.875rem;

.checkbox-wrapper {
    position: relative;

    display: flex;

    gap: $spacing-xs;

    justify-content: center;

    margin: auto 0;

    cursor: pointer;
}

.checkbox-wrapper:has(input:disabled) {
    cursor: not-allowed;
}

.checkbox {
    display: flex;

    flex-shrink: 0;

    align-items: center;
    justify-content: center;

    width: $checkbox-size;
    height: $checkbox-size;

    border: 1px solid var(--theme-color-border-primary-grey);
    border-radius: $border-radius-xs;

    transition: border-color 0.3s;

    .check-icon {
        display: none;

        fill: var(--theme-color-surface-primary-default);
    }
}

.input:checked ~ .checkbox {
    background-color: var(--theme-color-surface-accent-primary);
    border: 0;

    .check-icon {
        display: block;
    }
}

.input:indeterminate ~ .checkbox {
    background: none;
    border: $border-radius-xs solid var(--theme-color-surface-accent-primary);

    &::after {
        display: block;

        width: $checkbox-check-size;
        height: $checkbox-check-size;

        content: '';

        background-color: var(--theme-color-text-primary-light);
        border-radius: $border-radius-xxxs;
    }
}

.input:disabled ~ .checkbox {
    cursor: not-allowed;

    background-color: var(--theme-surface-secondary-default);
    border: 0;
}

.input:focus-visible ~ .checkbox,
.input:hover ~ .checkbox {
    border-color: var(--theme-color-icon-accent-1);
}

.input:disabled:checked ~ .checkbox {
    background-color: var(--theme-color-icon-quatriary);
}

.input:checked:focus-visible ~ .checkbox {
    background-color: var(--theme-color-border-accent);
}
</style>
