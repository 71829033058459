<template>
    <Component
        :is="tag"
        :id="id"
        :type="type"
        :href="href"
        :to="to"
        :data-cy="cypressId"
        :disabled="disabled"
        class="btn"
        :class="[customClass, {
            disabled: disabled,
        }]"
    >
        <slot />
    </Component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { type RouteLocationNamedRaw } from 'vue-router';
import { Variant } from './Button/Variant';
import { ButtonSize } from './Button/Size';

type Props = {
    id?: string | null,
    cypressId?: string | null,
    variant?: Variant[keyof Variant] | null,
    block?: boolean,
    circle?: boolean,
    to?: RouteLocationNamedRaw | null,
    href?: string | null,
    submit?: boolean,
    disabled?: boolean,
    hasOnlyIcon?: boolean,
    hasMixedContent?: boolean,
    shadowed?: boolean,
    noHorizontalPadding?: boolean,
    noResizeIcon?: boolean,
    size?: ButtonSize[keyof ButtonSize],
}

const props = withDefaults(
    defineProps<Props>(),
    {
        id: undefined,
        cypressId: null,
        size: ButtonSize.MEDIUM,
        variant: Variant.SECONDARY,
        block: true,
        to: null,
        href: null,
        circle: false,
        submit: false,
        disabled: false,
        hasOnlyIcon: false,
        hasMixedContent: false,
        shadowed: false,
        noHorizontalPadding: false,
        noResizeIcon: false,
    },
);

const isLink = computed(() => (props.to || props.href));

const tag = computed(() => {
    if (props.to) {
        return 'router-link';
    }

    if (props.href) {
        return 'a';
    }

    return 'button';
});

const type = computed(() => {
    if (isLink.value) {
        return undefined;
    }

    return props.submit ? 'submit' : 'button';
});

const customClass = computed(() => {
    const classes = [
        `btn--${props.size}`,
        `btn--${props.variant}`,
        props.block ? 'btn--block' : '',
        props.circle ? 'btn--circle' : '',
        props.hasOnlyIcon ? 'btn--has-only-icon' : '',
        props.hasMixedContent ? 'btn--mixed-content' : '',
        props.shadowed ? 'btn--shadowed' : '',
        props.noHorizontalPadding ? 'btn--no-horizontal-padding' : '',
        props.noResizeIcon ? 'btn--no-resize-icon' : '',
    ];

    return classes.join(' ').trim();
});
</script>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity */
@import '../../../styles/vendors/breakpoints';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/sizes';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/variables';

.btn {
    display: inline-flex;

    flex-direction: row;

    align-items: center;
    justify-content: center;

    height: 2.5rem;
    padding: $spacing-xxsm $spacing-s $spacing-xxsm $spacing-s;

    line-height: 1;

    color: var(--theme-color-text-primary);

    border: 0;

    border-radius: $border-radius;
    box-shadow: none;

    transition: 0.3s opacity linear, 0.3s background-color linear, 0.15s border-color linear;

    &:focus {
        box-shadow: none;
    }

    &:focus-visible {
        box-shadow: var(--shadow-3);
    }

    &--block {
        display: flex;
    }

    &--shadowed {
        box-shadow: var(--shadow-3);
    }

    &--lg {
        padding: $spacing-s $spacing-m $spacing-s $spacing-s;
    }

    &--sm {
        height: 2rem;
        padding: $spacing-xxsm $spacing-lm;

        font-size: $font-size-m;
    }

    &--xs {
        height: 1.75rem;
        padding: $spacing-xxs $spacing-xxxxs;

        border-radius: 4px;
    }

    &--xxs {
        height: 1.5rem;
        padding: $spacing-xxs $spacing-xs;

        border-radius: 4px;
    }

    &--xxxs {
        height: 1.25rem;
        padding: $spacing-xxs $spacing-xs;

        border-radius: 4px;
    }

    &--full {
        width: 100%;
        padding: $spacing-xxs $spacing-xs;

        border-radius: 8px;
    }

    &--circle {
        border-radius: 50%;
    }

    &--no-horizontal-padding {
        padding: $spacing-xxsm 0;
    }

    &.disabled,
    &:disabled {
        opacity: 1;
    }

    &-gray svg {
        fill: var(--theme-color-icon-inversed);
    }

    :deep svg {
        transition: 0.3s fill linear;
    }

    &:not(&--no-resize-icon) :deep svg {
        height: 100%;
    }
}

.btn--has-only-icon {
    width: 4rem;
    height: 4rem;
    padding: $spacing-s;

    @include media-breakpoint-up(lg) {
        width: 2.5rem;
        height: 2.5rem;
        padding: $spacing-xs;
    }

    &:not(.btn--no-resize-icon):deep svg,
    &:not(.btn--no-resize-icon):deep img {
        width: 100%;
    }

    &.btn--sm {
        width: 2rem;
        height: 2rem;
        padding: $spacing-xxs;
    }

    &.btn--md {
        width: 2.5rem;
        height: 2.5rem;
        padding: $spacing-xxs;
    }

    &.btn--lg {
        width: 2.5rem;
        height: 2.5rem;
        padding: $spacing-xs;

        border-radius: $border-radius;
    }

    &.btn--xs {
        width: 1.75rem;
        height: 1.75rem;
        padding: $spacing-xxxs;
    }

    &.btn--xxs {
        width: 1.5rem;
        height: 1.5rem;
        padding: $spacing-xxxs;
    }

    &.btn--xxxs {
        width: 1.25rem;
        height: 1.25rem;
        padding: $spacing-xxxs;
    }

    &.btn--full {
        width: 100%;
        height: 1.25rem;
        padding: $spacing-xxxs;
    }
}

// @todo specify for other sizes
.btn--mixed-content {
    &.btn--sm {
        padding: $spacing-xxs $spacing-xs $spacing-xxs $spacing-xxxs;
    }

    &.btn--md {
        padding: $spacing-xxsm $spacing-s $spacing-xxsm $spacing-xs;
    }
}

.btn--primary {
    color: var(--theme-color-surface-primary-default);

    background-color: var(--theme-color-button-primary);

    &:disabled,
    &.disabled {
        opacity: 50%;
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            color: var(--theme-color-text-inversed);

            background-color: var(--theme-color-button-primary-hover);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-primary-shadow);
        }
    }
}

.btn--secondary {
    @include text-icon-color(var(--theme-color-text-inversed));

    color: var(--theme-color-text-primary);

    background-color: var(--theme-color-button-accent);

    &:disabled,
    &.disabled {
        color: var(--theme-color-text-inversed);

        background-color: var(--theme-color-button-accent-disabled);
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            color: var(--theme-color-text-primary);

            background-color: var(--theme-color-button-accent-hover);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-accent-shadow);
        }
    }
}

.btn--dark-gray {
    background-color: var(--theme-color-surface-secondary-default);

    &:hover {
        color: var(--theme-color-text-primary);

        background-color: var(--theme-color-button-secondary-hover);
    }
}

.btn--light {
    @include text-icon-color(var(--theme-color-text-primary));

    background-color: var(--theme-color-surface-secondary-default);

    &:disabled,
    &.disabled {
        color: var(--theme-color-text-tetriary);

        background-color: var(--theme-color-button-secondary);
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            color: var(--theme-color-text-primary);

            background-color: var(--theme-color-surface-secondary-hover);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-light-shadow);
        }
    }
}

.btn--light-on-primary {
    @include text-icon-color(
        var(--theme-color-text-primary)
    );

    background-color: var(--theme-color-button-icon-secondary);

    &:disabled,
    &.disabled {
        background-color: var(--theme-color-button-secondary);

        &::v-deep svg {
            fill: var(--theme-color-text-tetriary);
        }
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            background-color: var(--theme-color-button-icon-secondary-hover);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-primary-shadow);
        }

        &:hover::v-deep svg,
        &:active::v-deep svg,
        &.active::v-deep svg {
            fill: var(--theme-color-icon-primary);
        }
    }
}

.btn--white {
    @include text-icon-color(
        var(--theme-color-text-primary)
    );
    background-color: var(--theme-color-surface-primary-default);
    border: 2px solid transparent;

    &:hover {
        color: var(--theme-color-text-primary);
    }

    &:disabled,
    &.disabled {
        @include text-icon-color(
            var(--theme-color-text-tetriary)
        );

        background-color: var(--theme-color-surface-secondary-default);
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            background-color: var(--theme-color-surface-inversed-secondary-hover);
            border-color: var(--theme-color-button-icon-secondary);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-light-shadow);
        }
    }
}

.btn--light-contrast {
    @include text-icon-color(
        var(--theme-color-icon-primary)
    );

    background-color: var(--theme-color-surface-secondary-default);

    &:disabled,
    &.disabled {
        @include text-icon-color(
            var(--theme-color-text-tetriary)
        );

        background-color: var(--theme-color-text-tetriary);

        svg {
            fill: var(--theme-color-text-tetriary);
        }
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            @include text-icon-color(
                var(--htme-color-icon-inversed)
            );

            color: var(--theme-color-text-inversed);

            background-color: var(--theme-color-button-primary);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-light-shadow);
        }
    }
}

.btn--dark {
    color: var(--theme-color-text-inversed);

    background-color: var(--theme-color-button-primary);

    &:disabled,
    &.disabled {
        background-color: var(--theme-color-text-tetriary);
    }

    &:not(:disabled):not(.disabled) {
        &:hover {
            color: var(--theme-color-text-inversed);

            background-color: var(--theme-color-surface-inversed-primary-hover);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-primary-shadow);
        }
    }
}

.btn--red {
    color: var(--theme-color-text-primary);

    background-color: var(--theme-color-button-critical);

    &:disabled,
    &.disabled {
        color: var(--theme-color-text-inversed);

        background-color: var(--theme-color-button-critical-disabled);
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            color: var(--theme-color-text-primary);

            background-color: var(--theme-color-button-critical-hover);
        }

        &:focus {
            box-shadow: none;
        }

        &:focus-visible {
            box-shadow: var(--shadow-button-focus) var(--theme-color-light-shadow);
        }
    }
}

.btn--gray {
    @include text-icon-color(
        var(--theme-color-text-primary)
    );
    background-color: var(--theme-color-button-secondary);
    border: 1px solid transparent;

    &:hover {
        color: var(--theme-color-text-primary);

        background-color: var(--theme-color-button-secondary-hover);
    }

    &:focus {
        box-shadow: none;
    }

    &:focus-visible {
        box-shadow: var(--shadow-button-focus) var(--theme-color-secondary-shadow);
    }
}

.btn--blue {
    color: var(--theme-color-text-link);

    background-color: var(--theme-color-surface-blue-light);

    transition: background-color 0.3s;

    &:hover,
    &:focus {
        color: var(--theme-color-text-link);

        background-color: var(--theme-color-surface-blue-light-on-hover);

        box-shadow: none;
    }

    &:focus-visible {
        box-shadow: var(--shadow-button-focus) var(--theme-color-light-shadow);
    }
}

.btn--transparent {
    background-color: transparent;

    &:hover,
    &:focus {
        color: var(--theme-color-text-primary);

        background-color: transparent;

        box-shadow: none;
    }

    &:focus-visible {
        box-shadow: var(--shadow-button-focus) var(--theme-color-light-shadow);
    }
}

.btn--bordered {
    @include text-icon-color(var(--theme-color-text-primary));

    background-color: var(--theme-color-surface-tetriary);
    border: 1px solid var(--theme-color-border-divider-primary);

    &:disabled,
    &.disabled {
        @include text-icon-color(var(--theme-color-text-tetriary));

        border: 1px solid var(--theme-color-border-divider-secondary);
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        &.active {
            color: var(--theme-color-text-primary);

            border: 1px solid var(--theme-color-border-accent);
        }

        &:focus {
            border: 0;

            box-shadow: none;
        }

        &:focus-visible {
            border: 1px solid  var(--theme-color-border-accent);
        }
    }
}

.btn--block + .btn--block {
    margin-top: 0;
}
</style>
